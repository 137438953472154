.splash-screen {
  background: -moz-linear-gradient(180deg, #1dece4 0%, #095bfa 100%);
  background: -webkit-linear-gradient(180deg, #1dece4 0%, #095bfa 100%);
  background: linear-gradient(180deg, #1dece4 0%, #095bfa 100%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  transition: left 0.5s;

  .splash-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      background: url('../../assets/logo/logo-vaimoo-white.svg');
      background-repeat: no-repeat;
      max-width: 100%;
      background-position: center;
      background-size: contain;
      width: 600px;
      height: 120px;
    }

    .label {
      margin-top: 32px;
      color: var(--surface-0);
      font-size: 2.5em;
      font-family: 'Poppins' !important;
    }

    .loader {
      background: url('../../assets/sidebar/three-dots.svg');
      background-repeat: no-repeat;
      max-width: 100%;
      background-position: center;
      background-size: contain;
      width: 80px;
      height: 80px;
      margin-top: -20px;
    }
  }
}
