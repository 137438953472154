.panel-fieldset {
  width: 100%;
  border: 1px solid var(--bright-blue-100);
  border-radius: 5px;
  padding: 1px;
  margin: 2px;
  .panel-legend {
    border: 1px solid var(--bright-blue-100);
    background-color: var(--bright-blue-100);
    color: var(--surface-0);
    margin-left: 1em;
    padding: 0.2em 0.8em;
  }
}

.panel-fieldset-no-border {
  width: 100%;
  border: none;
}

.panel-table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  margin: 5px auto;
  list-style: none;
  padding: 0;

  .spacer {
    width: 14px;
    height: 14px;
    font-size: 14px;
  }

  label {
    width: 50%;
    max-width: 50px;
    font-weight: 600;
    &:after {
      content: ':';
    }
  }
}

.panel-table > li.list-panel-table-skeleton {
  display: table-row;
  margin: 5px auto;
}

.panel-table > li.list-panel-table:nth-child(even):last-child {
  border: 0;
}

.panel-table > li.list-panel-table {
  display: table-row;
  margin: 5px auto;
  background-color: var(--surface-0);
  border-bottom: 1px solid var(--surface-c);
  &:last-child {
    border: 0;
  }
  &:hover {
    background: var(--surface-c);
  }
}

.panel-table > li.list-panel-table:nth-child(even) {
  background: var(--surface-g);
  &:hover {
    background: var(--surface-c);
  }
}

.panel-table > li.list-panel-table > * {
  display: table-cell;
  text-align: left;
  padding: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.panel-table > li.list-panel-table-skeleton > * {
  display: table-cell;
  text-align: left;
  padding: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.panel-empty-message {
  width: 100%;
  height: 200px;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}
