.layout-navbar {
  ul.navbar {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: -moz-linear-gradient(var(--background-gradient-2));
    background: -webkit-linear-gradient(var(--background-gradient-2));
    background: linear-gradient(var(--background-gradient-2));

    a.navbar-logo {
      width: 64px;
      height: 64px;
      color: var(--surface-0);
      display: block;
      text-decoration: none !important;
      border-right: 1px solid var(--a-turquoise-20);
      list-style: none;
      position: relative;
      background-image: url('../../assets/sidebar/logo.svg');
      &:hover {
        background-color: var(--a-turquoise-60);
      }
    }

    li.navbar-items {
      float: left;
      width: 64px;
      height: 64px;

      a.navbar-link {
        height: 64px;
        color: var(--surface-0);
        border-right: 1px solid var(--a-turquoise-20);
        display: block;
        text-decoration: none !important;
        list-style: none;
        position: relative;
        &:hover {
          background-color: var(--a-turquoise-60);
        }
      }
    }

    .active {
      color: var(--dark-blue-100);
      background-color: var(--surface-0);
      cursor: pointer;
    }

    .spacer {
      flex: 1;
      list-style: none;
      border-right: 1px solid var(--a-turquoise-20);
    }
  }

  .dropdown-mobile-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: var(--surface-125);
    min-width: 160px;
    box-shadow: var(--box-shadow-5);
    -webkit-box-shadow: var(--box-shadow-5);
    z-index: 999;

    a {
      color: var(--black-100);
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      :hover {
        background-color: var(--surface-65);
      }
    }
  }

  li.dropdown-mobile:hover .dropdown-mobile-content {
    display: block;
  }
}
