/*------------------------------ LAYOUT ------------------------------*/

.layout-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .layout-content.open {
    margin-left: 320px;
    transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  }

  .layout-content.close {
    margin-left: 64px;
    transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  }

  .layout-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .content-section {
      padding: 2rem 4rem;
      flex-grow: 1;
    }
  }
}
