h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0 0;
}

h1 {
  font-size: 40px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}

p.wide {
  width: 544px;
}

p.tiny {
  width: 272px;
}
