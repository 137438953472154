.layout-container-menu.open {
  width: 250px;
  transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-container-menu.close {
  width: 0;
  transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-container-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: var(--surface-75);
  user-select: none;
  transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  border-right: 1px solid var(--surface-d);
  z-index: 999;

  .layout-menu {
    overflow-y: auto;
    flex-grow: 1;

    .section-header {
      height: 64px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--surface-d);
    }

    .section-title {
      display: flex;
      width: 100%;
      height: 64px;
      flex-direction: column;
      align-items: flex-start;
      align-content: center;
      padding: 10px;
      justify-content: center;
    }

    .section-close {
      width: 64px;
      height: 64px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-content: center;
      padding: 10px;
      justify-content: center;

      i {
        font-size: 20px;
        &:hover {
          color: var(--bright-blue-100);
          border-radius: 50%;
        }
      }
    }

    .section-menu {
      font-size: 14px;
      padding: 0;
      margin: 0;

      .mainmenu,
      .child,
      .subChild,
      .subSubChild {
        list-style: none;
        padding: 5px;
        margin: 0;
      }

      .mainmenu a {
        display: block;
        text-decoration: none;
        padding: 5px;
      }

      .active-link {
        color: var(--bright-blue-100);
        font-weight: bold;
      }

      .mainmenu a:hover {
        background: var(--surface-c);
        color: var(--bright-blue-100);
        font-weight: bold;
      }

      .child a:hover {
        background: var(--surface-c);
        color: var(--bright-blue-100);
        font-weight: bold;
      }

      .subChild a:hover {
        background: var(--surface-c);
        color: var(--bright-blue-100);
        font-weight: bold;
      }

      .subSubChild a:hover {
        background: var(--surface-c);
        color: var(--bright-blue-100);
        font-weight: bold;
      }

      .mainmenu li:hover .child,
      .child li:hover .subChild,
      .subChild li:hover .subSubChild {
        display: block;
        height: 100%;
        max-height: 1000px;
      }

      .child,
      .subChild,
      .subSubChild {
        overflow: hidden;
        max-height: 0;
        transition: all 1s ease-out;
        -webkit-transition: all 1s ease-out;
      }
    }
  }
}
