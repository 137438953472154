.va-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: cover;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

i.va-icon-size {
  font-size: 25px;
  &.va-active {
    color: #047dff;
  }
}

.va-user {
  background-image: url(../../assets/sidebar/user.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
  &.va-active {
    filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
  }
}

.va-accounting {
  background-image: url(../../assets/sidebar/accounting.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
  &.va-active {
    filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
  }
}

.va-assets {
  background-image: url(../../assets/sidebar/assets.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
  &.va-active {
    filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
  }
}

.va-config {
  background-image: url(../../assets/sidebar/config.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
  &.va-active {
    filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
  }
}

.va-search {
  background-image: url(../../assets/sidebar/search.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
  &.va-active {
    filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
  }
}

.va-setting {
  background-image: url(../../assets/sidebar/settings.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
  &.va-active {
    filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
  }
}

.va-sharing {
  background-image: url(../../assets/sidebar/sharing.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
  &.va-active {
    filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
  }
}

.va-tenant {
  background-image: url(../../assets/sidebar/tenant.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
  &.va-active {
    filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
  }
}

.va-trips {
  background-image: url(../../assets/sidebar/trips.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
  &.va-active {
    filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
  }
}

.va-admin {
  background-image: url(../../assets/sidebar/admin.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
  &.va-active {
    filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
  }
}

.va-monitoring {
  background-image: url(../../assets/sidebar/monitoring.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
  &.va-active {
    filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
  }
}

.va-maintenance {
  background-image: url(../../assets/sidebar/settings.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
  &.va-active {
    filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
  }
}

.va-system {
  background-image: url(../../assets/sidebar/system.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
  &.va-active {
    filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
  }
}

.va-logo {
  background-image: url(../../assets/sidebar/logo.svg);
}
