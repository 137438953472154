ul.panel-list {
  padding: 0;
  display: flex;
  flex-direction: column;
  li.panel-list-item {
    height: 25px;
    color: var(--black-80);
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      background: var(--surface-c);
      color: var(--bright-blue-100);
    }
  }
}

ul.panel-list li.panel-list-item:before {
  content: '•';
  font-size: 24px;
  margin-left: 18px;
  margin-right: 5px;
}
