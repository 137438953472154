/*------------------------------ PROGRESSBAR ------------------------------*/

.p-progressbar {
  margin: 8px 0 0 0;
  width: 72% !important;
  float: right;
}

.p-progressbar-determinate {
  height: 10px !important;
}

.p-element.red {
  .p-progressbar-value {
    background: var(--red-100) !important;
  }
}

.p-element.yellow {
  .p-progressbar-value {
    background: var(--yellow-100) !important;
  }
}

.p-element.green {
  .p-progressbar-value {
    background: var(--green-100) !important;
  }
}

.percentage {
  width: 25%;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  display: inline-block;
}

.color-gray {
  color: var(--black-20);
}

.color-red {
  color: var(--red-100);
}

.color-yellow {
  color: var(--yellow-100);
}

.color-green {
  color: var(--green-100);
}
