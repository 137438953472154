.value-content {
  display: flex;
  width: 100%;
  height: 25px;

  .value {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }

  .icon {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }
}
