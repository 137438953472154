html {
  font-size: 14px;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  background-color: var(--surface-65);
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
}

a {
  cursor: pointer;
  color: var(--black-80);
}

hr {
  border-top: 1px solid var(--black-20);
  margin: 0;
}

.hidden {
  display: none;
}

.right {
  float: right;
}

.left {
  float: left;
}

.text-center {
  text-align: center;
}

.icon {
  line-height: 64px;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.p-component {
  font-family: var(--font-family) !important;
}

.icon-pointer {
  cursor: pointer;
}

.icon-no-pointer {
  cursor: not-allowed;
}

/*------------------------------ PAGE ------------------------------*/

.content-page {
  padding: 10px;
  overflow-y: auto;
}

.map-section {
  position: relative;
}

.buttons-section {
  display: flex;
  width: 100%;
  margin: 0 0 10px 0;
  justify-content: flex-end;
  .start {
    display: flex;
    width: 50%;
    justify-content: flex-start;
  }
  .end {
    display: flex;
    width: 50%;
    justify-content: flex-end;
  }
}

/*------------------------------ LOGIN PANNEL------------------------------*/

.login-panel {
  position: absolute;
  width: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/*------------------------------ TABLE PANNEL ------------------------------*/

th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*------------------------------ ACCORDION ------------------------------*/

.p-accordion-header {
  outline: 0 none;
  outline-offset: 0;
}

.p-accordion .p-accordion-content {
  padding: 0 !important;
}

/*------------------------------ OVERLAY ------------------------------*/

.p-overlaypanel .p-overlaypanel-content {
  padding: 0 !important;
}

.p-overlaypanel:before {
  border: none !important;
  border-color: none !important;
  border-bottom-color: none !important;
}

.p-overlaypanel:after {
  border: none !important;
  border-color: none !important;
  border-bottom-color: none !important;
}

/*------------------------------ TOOLTIP ------------------------------*/

.p-tooltip {
  max-width: 100% !important;
}

/*------------------------------ DRAG N DROP ------------------------------*/

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 5px rgba(0, 0, 0, 0.12);
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging {
  background: var(--a-turquoise-10);
}

.cdk-drop-list-dragging .card:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.placeholder {
  background: var(--gray-325);
  border: dotted 3px var(--black-40);
  min-height: 200px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/*------------------------------ DIALOG ------------------------------*/

.dynamicDialog .p-dialog-content {
  padding: 0 1.5rem 0rem 1.5rem !important;
}

.dynamicDialog .p-dialog-title {
  font-size: 28px !important;
}

/*------------------------------ BOARD ------------------------------*/

.board-style {
  width: 100px;
  border-radius: 4px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.3px;
  text-align: center;

  &.board-NotStarted {
    background-color: var(--board-gray);
    color: var(--board-text);
  }
  &.board-Started {
    background-color: var(--board-gray);
    color: var(--board-text);
  }
  &.board-Closed {
    background-color: var(--board-gray);
    color: var(--board-text);
  }
  &.board-Completed {
    background-color: var(--board-gray);
    color: var(--board-text);
  }
}

/*------------------------------ ISSUE SEVERITY ------------------------------*/

.severity {
  width: 100px;
  border-radius: 4px;
  padding: 0.25em 0.5rem;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  text-align: center;

  &.Blocking {
    background-color: var(--severity-blocking);
    color: var(--severity-color-blocking);
  }
  &.Major {
    background-color: var(--severity-major);
    color: var(--severity-color-major);
  }
  &.Minor {
    background-color: var(--severity-minor);
    color: var(--severity-color-minor);
  }
}
