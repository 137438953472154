.notFound,
.notAuthorized {
  width: 100%;
  height: 100%;
  padding: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: -moz-linear-gradient(var(--background-gradient-1));
  background: -webkit-linear-gradient(var(--background-gradient-1));
  background: linear-gradient(var(--background-gradient-1));

  .logo {
    background: url('../../assets/logo/logo-vaimoo-white.svg');
    background-repeat: no-repeat;
    max-width: 90%;
    background-position: center;
    background-size: contain;
    width: 600px;
    height: 120px;
  }

  .icon-loader {
    margin: 5px;
  }

  .button a {
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background: var(--bright-blue-100);
    display: inline-block;
    padding: 15px 30px;
    border-radius: 40px;
    color: var(--surface-0);
    font-weight: 700;
    -webkit-box-shadow: var(--box-shadow-8);
    box-shadow: var(--box-shadow-8);
    &:hover {
      background-color: var(--dark-blue-100);
    }
  }
}

.circle-line {
  fill: var(--surface-125);
}

.number-inf {
  fill: var(--bright-blue-80);
}

.number-sup {
  fill: var(--bright-blue-100);
}

// manubrio
.pomello {
  fill: var(--black-100);
}

.sellino {
  fill: var(--black-100);
}

.telaio-anteriore {
  fill: var(--black-60);
}

.telaio-centrale {
  fill: var(--black-100);
  opacity: 0.4;
}

.ruote {
  fill: var(--black-80);
}

/* animations */

.wheel {
  animation: wheel-rotate 6s ease infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes wheel-rotate {
  50% {
    transform: rotate(360deg);
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  100% {
    transform: rotate(960deg);
  }
}

#bike {
  animation: bike-anim 6s ease infinite;
}

@keyframes bike-anim {
  0% {
    transform: translateX(-1300px);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  100% {
    transform: translateX(1300px);
  }
}

.circle {
  animation: circle-anim ease infinite;
  transform-origin: center;
  transform-box: fill-box;
  perspective: 0px;
}

.circle.c1 {
  animation-duration: 2s;
}

.circle.c2 {
  animation-duration: 3s;
}

.circle.c3 {
  animation-duration: 1s;
}

.circle.c4 {
  animation-duration: 1s;
}

.circle.c5 {
  animation-duration: 2s;
}

.circle.c6 {
  animation-duration: 3s;
}

@keyframes circle-anim {
  50% {
    transform: scale(0.2) rotateX(360deg) rotateY(360deg);
  }
}

.four,
#ou {
  animation: four-anim cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
}

.four.a {
  transform-origin: bottom left;
  animation-duration: 3s;
  transform-box: fill-box;
}

.four.b {
  transform-origin: bottom right;
  animation-duration: 3s;
  transform-box: fill-box;
}

#ou {
  animation-duration: 6s;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes four-anim {
  50% {
    transform: scale(0.98);
  }
}
