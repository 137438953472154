.va-button-primary {
  color: var(--surface-0) !important;
  background-color: var(--bright-blue-100) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border: none !important;
  &:hover {
    background-color: var(--dark-blue-100) !important;
  }
  &:focus {
    border: 1px solid var(--a-turquoise-100) !important;
  }
  &:disabled {
    color: var(--black-40) !important;
    background-color: var(--black-20) !important;
    cursor: not-allowed !important;
  }
}

.va-button-secondary {
  color: var(--bright-blue-100) !important;
  background-color: var(--dark-blue-20) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border: none !important;
  &:hover {
    color: white !important;
    background-color: var(--bright-blue-100) !important;
  }
  &:focus {
    border: 1px solid var(--a-turquoise-100) !important;
  }
  &:disabled {
    color: var(--black-40) !important;
    background-color: var(--black-20) !important;
    cursor: not-allowed !important;
  }
}

.va-button-tertiary {
  color: var(--black-100) !important;
  background-color: var(--surface-0) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border: 1px solid var(--black-20) !important;
  &:hover {
    color: var(--black-100) !important;
    border: 1px solid var(--bright-blue-100) !important;
  }
  &:focus {
    color: var(--bright-blue-100) !important;
    border: 1px solid var(--a-turquoise-100) !important;
  }
  &:disabled {
    color: var(--black-40) !important;
    cursor: not-allowed !important;
  }
}

.va-button-ghost {
  color: var(--bright-blue-100) !important;
  background-color: transparent !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border: none !important;
  &:hover {
    background-color: var(--dark-blue-20) !important;
  }
  &:focus {
    border: 1px solid var(--a-turquoise-100) !important;
  }
  &:disabled {
    color: var(--black-40) !important;
    cursor: not-allowed !important;
  }
}

.va-button-filter-item {
  color: var(--bright-blue-100) !important;
  background-color: var(--dark-blue-20) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border: none !important;
  border-radius: 0 !important;
  &:hover {
    color: var(--bright-blue-100) !important;
    border-bottom: 2px solid var(--bright-blue-100) !important;
  }
  &:focus {
    color: var(--bright-blue-100) !important;
    border-bottom: 2px solid var(--bright-blue-100) !important;
  }
}

.va-button-filter {
  color: var(--bright-blue-100) !important;
  background-color: var(--dark-blue-20) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border: none !important;
  border-radius: 0 !important;
  &:hover {
    color: var(--surface-0) !important;
    background-color: var(--bright-blue-100) !important;
  }
  &:focus {
    border: 1px solid var(--a-turquoise-100) !important;
  }
  &:disabled {
    color: var(--black-40) !important;
    background-color: var(--black-20) !important;
    cursor: not-allowed !important;
  }
}

.va-button {
  box-shadow: none !important;
  margin: 2px !important;
  width: auto !important;
  min-width: 100px;
  height: 48px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.va-button-only-icon {
  box-shadow: none !important;
  margin: 2px !important;
  width: 48px !important;
  height: 48px !important;
}
