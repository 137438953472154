//ON CONTAINER
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: var(--surface-0) !important;
  border: 1px solid var(--black-20);
}

//ON
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: var(--bright-blue-100) !important;
}

//OFF CONTAINER
.p-inputswitch .p-inputswitch-slider {
  background: var(--surface-0) !important;
  border: 1px solid var(--black-20);
}

//OFF
.p-inputswitch .p-inputswitch-slider:before {
  background: var(--dark-blue-100) !important;
}
