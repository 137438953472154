@media screen and (min-width: 1729px) {
  .layout-header-container {
    .layout-header {
      .menu {
        display: none;
      }
    }
  }

  .layout-content-inner {
    width: 100%;
    margin: 0;
  }

  .layout-container-menu {
    display: none;
  }
  .repair-sidebar .repair-sidebar.repair-sidebar-active {
    width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .layout-header-container {
    .layout-header {
      .menu {
        display: none;
      }
    }
  }

  .layout-container-menu {
    display: none;
  }
  .repair-sidebar .repair-sidebar.repair-sidebar-active {
    width: 100%;
  }
}

@media screen and (min-width: 960px) {
  .layout-header-container {
    .layout-header {
      .menu {
        display: none;
      }
    }
  }

  .layout-container-menu {
    display: none;
  }
  .repair-sidebar .repair-sidebar.repair-sidebar-active {
    width: 100%;
  }
}

@media screen and (max-width: 959px) {
  .splash-screen {
    .splash-inner {
      .logo {
        width: 300px;
        height: 120px;
      }
    }
  }

  .layout-sidebar {
    top: 0;
    z-index: 1102;
    height: 100%;
    transform: translateX(-110%);
  }

  .layout-wrapper {
    .layout-content.open {
      margin-left: 0;
    }

    .layout-content.close {
      margin-left: 0;
    }
    .layout-content {
      margin-left: 0;
    }
  }

  .repair-sidebar .repair-sidebar.repair-sidebar-active {
    width: 100%;
  }

  .notFound,
  .notAuthorized {
    padding: 30px;
  }
}

@media screen and (max-width: 640px) {
}
