.va-user-container {
  display: flex;
  align-items: center;
}

.va-user-circle {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  border: 2px solid var(--surface-0);
  color: var(--surface-0);
  font-weight: bold;
  background: var(--bright-blue-100);
}
