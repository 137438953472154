.user-header {
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  padding: 15px;
  color: var(--text-color);

  .user-image {
    margin: 12px 12px 12px 0;
  }

  .user-information {
    display: flex;
    flex-direction: column;

    h6.user-title {
      margin: 12px 0 0 0;
    }

    p.user-subtitle {
      color: var(--black-80);
      font-size: 12px;
      margin: 4px 0 0 0;
    }
  }
}

.user-language {
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  color: var(--text-color);
  border-top: 1px solid #d8d8d8;
}

.user-menu {
  border-top: 1px solid var(--surface-h);
  padding: 5px 0 5px 0;

  .user-logout {
    display: flex;
    height: 50px;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }

  li.user-link {
    padding: 24px;
    display: flex;
    height: 40px;
    color: var(--black-80);
    flex-direction: row;
    flex-wrap: nowrap;
    list-style: none;
    align-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: var(--surface-c);
      color: var(--bright-blue-100);
      font-weight: bold;
    }

    .user-icon {
      font-size: 14px;
    }
  }
}
