.filter-container {
  width: 100%;
  display: flex;

  .filter-selected {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;

    .filter-selected-span {
      display: flex;
      margin: 10px;
    }
  }

  .filter-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.advanced-filters {
  position: absolute;
  right: 24px;
}

.filter-badge-label {
  width: auto;
  height: 48px;
  color: var(--bright-blue-100);
  background-color: var(--bright-blue-20);
  font-size: 12px;
  margin: 2px;
  padding: 10px;
  text-align: center;
  .value {
    margin-left: 10px;
  }
}

.filter-badge-op {
  width: auto;
  height: 24px;
  display: flex;
  color: var(--surface-0);
  background-color: var(--green-100);
  font-size: 12px;
  margin: 2px;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

//------------------------------ OPERATORS / TYPES ------------------------------

.label-container {
  display: flex;
  width: 100%;
  .start {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
  }
  .end {
    display: flex;
    height: 30px;
    justify-content: flex-end;
    width: 100%;
  }
}

.badge-content {
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.badge-operator {
  background-color: var(--green-100);
  width: 90px;
  border-radius: 10px;
  padding: 1px;
  color: var(--surface-0);
  font-size: 11px;
  margin-left: 2px;
  margin-right: 2px;
  text-align: center;
}

.badge-type {
  background-color: var(--bright-blue-100);
  width: 90px;
  border-radius: 10px;
  padding: 1px;
  color: var(--surface-0);
  font-size: 11px;
  margin-left: 2px;
  margin-right: 2px;
  text-align: center;
}

ul.item-ot {
  list-style: none;
  padding: 0;
  li {
    list-style: none;
    &:hover {
      background: var(--surface-c);
    }
    .desc {
      margin-left: 5px;
      padding: 0.2rem 1rem;
      color: var(--gray-725);
    }
  }
}

.display-none {
  display: none;
}
