/*------------------------------ TABLE ------------------------------*/

/*------------------------------ TABLE MENU ------------------------------*/

button {
  height: 30px;
}

/*------------------------------ COLUMN OVERLAY ------------------------------*/

ul.column-settings {
  font-size: 14px;
  list-style: none;
  padding: 0;
  li.column-item {
    list-style: none;
    &:hover {
      background: #e9ecef;
    }
    .item {
      margin-left: 5px;
      padding: 0.2rem 1rem;
      color: #495057;
    }
  }
}

/*------------------------------ OTHER ------------------------------*/

.settings {
  width: 2.25em;
}

.icon-loader {
  font-size: 24px;
  margin: 10px;
}

.emptymessage {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  background-color: var(--surface-65);

  i {
    font-size: 50px;
  }
}
