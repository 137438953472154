@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeflex/primeflex.css';
@import '../node_modules/primeng/resources/themes/saga-blue/theme.css';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';

:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --surface-g: #fcfcfc;
  --surface-h: #d8d8d8;

  --text-color: #495057;
  --text-color-secondary: #6c757d;

  --primary-color: #2196f3;
  --primary-color-text: #ffffff;

  --font-family: 'Poppins', sans-serif !important;

  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-65: #f1f1f1;
  --surface-75: #f8f9fa;
  --surface-100: #f5f5f5;
  --surface-125: #f9f9f9;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-325: #e8ebf0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;

  --black-100: #000000;
  --black-80: #333333;
  --black-60: #666666;
  --black-40: #999999;
  --black-20: #d2d2d2;

  --gray-50: #fafafa;
  --gray-100: #f5f5f5;
  --gray-200: #eeeeee;
  --gray-300: #e0e0e0;
  --gray-325: #cccccc;
  --gray-400: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-725: #495057;
  --gray-800: #424242;
  --gray-900: #212121;

  --dark-blue-100: #17234d;
  --dark-blue-80: #454f70;
  --dark-blue-60: #737b94;
  --dark-blue-50: #b3b8cd;
  --dark-blue-40: #a2a7b7;
  --dark-blue-20: #d0d3db;

  --bright-blue-100: #047dff;
  --bright-blue-80: #3697ff;
  --bright-blue-60: #68b1ff;
  --bright-blue-40: #9acbff;
  --bright-blue-20: #cce5ff;

  --a-turquoise-100: #19e8e0;
  --a-turquoise-80: #47ece6;
  --a-turquoise-60: #75f1ec;
  --a-turquoise-40: #a3f6f3;
  --a-turquoise-20: #d1fbf9;
  --a-turquoise-10: #d1f5ed;

  --purple-100: #f50089;
  --purple-80: #f733a0;
  --purple-60: #f966b8;
  --purple-40: #fb99d0;
  --purple-20: #fdcce7;

  --violet-100: #7209b7;
  --violet-80: #8e3ac5;
  --violet-60: #aa6bd4;
  --violet-40: #c69ce2;
  --violet-20: #e3cdf1;

  --red-100: #f40e3a;
  --red-80: #f63e61;
  --red-60: #f86e88;
  --red-40: #fb9eb0;
  --red-20: #fdced7;

  --orange-100: #ff832c;
  --orange-80: #ff9b56;
  --orange-60: #ffb480;
  --orange-40: #ffe7b0;
  --orange-20: #fff3d7;

  --yellow-110: #fea23a;
  --yellow-100: #fec23a;
  --yellow-80: #fece61;
  --yellow-60: #feda88;
  --yellow-40: #ffe7b0;
  --yellow-20: #fff3d7;

  --green-100: #24a171;
  --green-80: #4fb38d;
  --green-60: #7bc6a9;
  --green-40: #a7d9c6;
  --green-20: #d3ece2;

  --content-padding: 1rem;
  --inline-spacing: 0.5rem;
  --border-radius: 3px;

  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;

  --maskbg: rgba(0, 0, 0, 0.4);
  --focus-ring: 0 0 0 0.2rem #a6d5fa;

  --box-shadow-1: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  --box-shadow-2: 1px 0 5px -2px rgb(57 63 72 / 30%);
  --box-shadow-3: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
  --box-shadow-4: 0 4px 2px -2px rgba(57 63 72 / 30%);
  --box-shadow-5: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  --box-shadow-6: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
  --box-shadow-7: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-8: 0px 4px 15px -5px #0046d5;
  --box-shadow-9: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);

  --background-gradient-1: 180deg, rgb(25, 227, 219) 0%, rgb(5, 127, 247) 100%;
  --background-gradient-2: 90deg, rgb(25, 227, 219) 0%, rgb(5, 127, 247) 100%;
  --background-gradient-3: 125deg, rgb(25, 227, 219) 0%, rgb(5, 127, 247) 100%;

  /*------------------------------ BOARD ------------------------------*/

  --board-not-started: #b3e5fc;
  --board-started: #ffd8b2;
  --board-closed: #ffcdd2;
  --board-completed: #c8e6c9;

  --board-color-not-started: #23547b;
  --board-color-started: #805b36;
  --board-color-closed: #c63737;
  --board-color-completed: #256029;

  --board-gray: #e6e6e6;
  --board-text: var(--black-80);

  /*------------------------------ SEVERITY ------------------------------*/

  --severity-blocking: #ffcdd2;
  --severity-major: #ffd8b2;
  --severity-minor: #c8e6c9;

  --severity-color-blocking: #c63737;
  --severity-color-major: #805b36;
  --severity-color-minor: #256029;
}

/*------------------------------ POWERBI ------------------------------*/

iframe {
  border: none;
  background-color: transparent;
}

.report-container {
  height: 100vh;
  width: 100%;
  background-color: transparent;
}

/*------------------------------ MULTISELECT ------------------------------*/

// allow the multiselect to show all the chips, without ellipsis
p-multiselect {
  .p-multiselect-label {
    display: flex;
    flex-wrap: wrap;
    gap: 0.1rem;
  }
}