.section {
  position: absolute;
  top: 0;
  left: 64px;
  width: 256px;
  height: 100%;
}

.section-header {
  height: 64px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--surface-d);
}

.section-title {
  display: flex;
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  padding: 10px;
  justify-content: center;
}

.section-close {
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: center;
  padding: 10px;
  justify-content: center;

  i {
    font-size: 20px;
    &:hover {
      color: var(--bright-blue-100);
      border-radius: 50%;
    }
  }
}

i.section-icon {
  font-size: 18px;
  font-weight: bold;
  float: right;
}

ul.section-menu {
  padding: 0px;

  li {
    list-style: none;

    a.section-link {
      padding: 12px;
      display: block;
      text-decoration: none !important;
      list-style: none;
      position: relative;
      &:hover {
        color: var(--bright-blue-100);
        background-color: var(--surface-c);
        font-weight: bold;
      }
    }

    .active-section {
      color: var(--bright-blue-100);
      font-weight: bold;
    }
  }
}

ul.section-menu li.section-item {
  display: block;
}

ul.section-menu::before li.section-item {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 0;
  bottom: 0;
}
