.layout-sidebar.open {
  width: 320px;
  transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-sidebar.close {
  width: 64px;
  transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background-color: var(--surface-75);
  user-select: none;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  border-right: 1px solid var(--surface-d);
  display: flex;
  flex-direction: column;

  a.sidebar-logo {
    width: 64px;
    height: 70px;
    color: var(--surface-0);
    display: block;
    text-decoration: none !important;
    list-style: none;
    position: relative;
    background-image: url('../../assets/sidebar/logo.svg');
    &:hover {
      background-color: var(--a-turquoise-60);
    }
  }

  .sidebar {
    width: 64px;
    height: 100vh;
    background: -moz-linear-gradient(var(--background-gradient-1));
    background: -webkit-linear-gradient(var(--background-gradient-1));
    background: linear-gradient(var(--background-gradient-1));

    ul.sidebar-menu {
      display: flex;
      flex-direction: column;
      height: calc(100% - 64px);
      margin: -6px 0 0 0;
      padding: 0px;

      li.sidebar-items {
        list-style: none;

        a.sidebar-link {
          height: 64px;
          color: var(--surface-0);
          border-top: 1px solid var(--a-turquoise-20);
          display: block;
          text-decoration: none !important;
          list-style: none;
          position: relative;
          &:hover {
            background-color: var(--a-turquoise-60);
          }
        }

        .active-sidebar {
          color: var(--dark-blue-100);
          background-color: var(--surface-0);
          border-right: 1px solid var(--surface-c);
        }
      }
    }
  }

  .spacer {
    flex: 1;
    list-style: none;
    border-top: 1px solid var(--a-turquoise-20);
  }

  .footer-version {
    width: 100%;
    height: 64px;
    padding: 10px;
    display: flex;
    position: absolute;
    bottom: 0;
    background-color: var(--surface-125);
    border-top: 1px solid var(--black-40);
    align-items: center;
    text-align: center;
    justify-content: center;
  }
}
