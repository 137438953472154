/* RADIOBUTTON */

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 10px !important;
  height: 10px !important;
  transition-duration: 0.2s !important;
  background-color: var(--bright-blue-100) !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border: 2px solid var(--black-20) !important;
  background: var(--surface-0) !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight:hover {
  border: 2px solid var(--black-60) !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border: 2px solid var(--black-60) !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: 2px solid var(--a-turquoise-100) !important;
}

.p-radiobutton .p-radiobutton-box:is(.p-disabled) {
  border: 2px solid var(--black-60) !important;
  background: var(--black-20) !important;
}

.p-radiobutton .p-radiobutton-box:is(.p-disabled) .p-radiobutton-icon {
  background: var(--black-60) !important;
}

/* CHECKBOX */
.checkbox-content {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

/* TEXT FIELD */

.p-inputtext:hover {
  box-shadow: none !important;
  border-color: var(--a-turquoise-100) !important;
}

.p-inputtext:enabled:hover {
  box-shadow: none !important;
  border-color: var(--a-turquoise-100) !important;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: 1px solid var(--a-turquoise-100) !important;
}

/* DROPDOWN */

.p-dropdown {
  width: 240px;
}

.p-dropdown:not(.p-disabled):hover {
  border: 1px solid var(--a-turquoise-100) !important;
}

.p-dropdown:is(.p-focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: 1px solid var(--a-turquoise-100) !important;
}

/* MULTISELECTION */

.p-multiselect-label:is(.p-focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: 1px solid var(--a-turquoise-100) !important;
}

.p-multiselect:not(.p-disabled):hover {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: 1px solid var(--a-turquoise-100) !important;
}
