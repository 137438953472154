.tab-container {
  padding: 0 0 0 24px;
}

.tablink:first-child {
  border-top-left-radius: 3px;
}

.tablink:last-child {
  border-top-right-radius: 3px;
}

.tablink {
  width: 100px;
  height: auto;
  color: var(--bright-blue-100);
  background: var(--surface-65);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  &:hover {
    background: var(--bright-blue-100);
    color: var(--surface-0);
  }
}

.tab-active {
  background: var(--bright-blue-100);
  color: var(--surface-0);
}
