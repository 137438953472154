.layout-config {
  position: fixed;
  padding: 0;
  top: 0;
  right: 0;
  display: block;
  width: 400px;
  z-index: 990;
  height: 100%;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  transform: translateX(100%);
  backface-visibility: hidden;

  &.layout-config-active {
    transform: translateX(0);

    .layout-config-content-wrapper {
      .layout-config-button {
        i {
          transform: rotate(0deg);
        }
      }
    }
  }

  .layout-config-content-wrapper {
    position: relative;
    height: 100%;
    box-shadow: var(--box-shadow-6);
    -webkit-box-shadow: var(--box-shadow-6);
    background-color: var(--surface-0);

    .layout-config-button {
      display: block;
      position: absolute;
      width: 52px;
      height: 52px;
      line-height: 52px;
      background-color: var(--bright-blue-100);
      color: var(--surface-0);
      text-align: center;
      top: 270px;
      left: -51px;
      z-index: -1;
      overflow: hidden;
      cursor: pointer;
      outline: 0 none;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      box-shadow: var(--box-shadow-7);
      -webkit-box-shadow: var(--box-shadow-7);

      i {
        font-size: 26px;
        line-height: inherit;
        cursor: pointer;
        transform: rotate(360deg);
        transition: transform 1s;
      }
    }
  }

  a {
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }

  .layout-config-content {
    overflow: auto;
    height: 100%;
    padding: 2rem;
  }

  .config-scale {
    display: flex;
    align-items: center;

    .p-button {
      margin-right: 0.5rem;
    }

    i {
      margin-right: 0.5rem;
      font-size: 0.75rem;

      &.scale-active {
        font-size: 1.25rem;
      }
    }
  }

  .layout-config-close {
    position: absolute;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    right: 20px;
    top: 20px;
    z-index: 999;
    background-color: var(--surface-0);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;

    i {
      line-height: inherit;
      font-size: 14px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

.fieldset-config {
  border: 1px solid var(--surface-65);
  border-radius: 5px;
  margin: 30px 5px 30px 5px;
  padding: 10px;
  .panel-legend {
    border: 1px solid var(--surface-65);
    background-color: var(--surface-65);
    margin-left: 1em;
    padding: 0.2em 0.8em;
    font-weight: bold;
    font-size: 18px;
  }
}

footer {
  display: flex;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--surface-0);
  box-shadow: var(--box-shadow-1);
  -webkit-box-shadow: var(--box-shadow-1);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
