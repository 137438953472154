/*------------------------------ BADGE ------------------------------*/

.badge {
  display: block;
  width: 50px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
}

.badge-power {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.badge-charge {
  display: block;
  width: 50px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
}
/*------------------------------ POWER ------------------------------*/

.power-green {
  background: var(--green-100);
}

.power-grey {
  background: var(--black-20);
}

/*------------------------------ PROGRESS ------------------------------*/

.progress-green {
  background: var(--green-100);
  color: var(--green-100);
}

.progress-grey {
  background: var(--black-20);
  color: var(--black-20);
}

.progress-red {
  background: var(--red-100);
  color: var(--red-100);
}

.progress-yellow {
  background: var(--yellow-100);
  color: var(--yellow-100);
}

/*------------------------------ CHARGE ------------------------------*/

.charge-green {
  border: 2px solid var(--green-100);
  background: var(--green-20);
  color: var(--green-100);
}

.charge-grey {
  border: 2px solid var(--black-20);
  background: var(--black-20);
  color: var(--black-100);
}

.charge-red {
  border: 2px solid var(--red-100);
  background: var(--red-20);
  color: var(--red-100);
}

.charge-yellow {
  border: 2px solid var(--yellow-100);
  background: var(--yellow-20);
  color: var(--yellow-110);
}

/*------------------------------ COLORS ------------------------------*/

.green {
  background: var(--green-100);
  color: var(--green-100);
}

.grey {
  background: var(--black-20);
  color: var(--black-100);
}

.red {
  background: var(--red-100);
  color: var(--red-100);
}

.yellow {
  background: var(--yellow-100);
  color: var(--yellow-100);
}

.blue {
  background: var(--bright-blue-100);
  color: var(--surface-0);
}

.color-grey {
  color: var(--black-20);
}

.color-black {
  color: var(--black-100);
}

.color-green {
  color: var(--green-100);
}
