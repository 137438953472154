.spinner {
  position: fixed;
  z-index: 1100;
  height: 150px;
  width: 150px;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--maskbg);
  }

  .spinner-logo {
    width: 90px;
    height: 90px;
    z-index: 9999;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-image: url('../../assets/sidebar/logo.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/*------------------------------ SPINNER NG PRIME OVERRIDE ------------------------------*/

.spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite,
    custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
  100%,
  0% {
    stroke: var(--a-turquoise-20);
  }
  40% {
    stroke: var(--a-turquoise-100);
  }
  66% {
    stroke: var(--bright-blue-100);
  }
  80%,
  90% {
    stroke: var(--dark-blue-100);
  }
}

@keyframes custom-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

/*------------------------------ NG-PROGRESSBAR ------------------------------*/

.ng-bar-placeholder {
  height: 8px !important;
}

.ng-bar {
  background: -moz-linear-gradient(var(--background-gradient-2)) !important;
  background: -webkit-linear-gradient(var(--background-gradient-2)) !important;
  background: linear-gradient(var(--background-gradient-2)) !important;
}
